import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import carChargingDashboard from "../assets/dashBoard/carChargingDashboard.png";
import Coupon from "../assets/Coupon.png";
import CustomOptionsModal from "./customComponents/CustomOptionsModal";
import DynamicFormModal from "./customComponents/DynamicFormModal";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const CustomCard = ({ cardData, onClick, isSelected, onSelect, apiCallType = "editStation" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalType, setDynamicModalType] = useState("");
  const isLongText = cardData.name.length > 25;
  const userStatus = useSelector((state) => state.user.data.data);

  const handleClick = () => {
    if (!isModalOpen && !isDynamicModalOpen) {
      onClick && onClick(cardData.id);
      onSelect && onSelect(cardData.id);
    }
  };

  const handleOpenModal = (id, name, event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
    setId(`Station ID: ${id}`);
    setSelectedCharger({ id, name });
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const handleEditFormOpen = () => {
    const newApiCallType = apiCallType;
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsModalOpen(false);
    setIsDynamicModalOpen(true);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          marginBottom: "12px",
          cursor: "pointer",
          border: isSelected ? "1px solid #B5CBF7" : "1px solid #EAEBEE",
          background: isSelected ? "#EFF4FF" : "#FFF",
          borderRadius: "8px",
          position: "relative",
          boxShadow: "none",
        }}
        onClick={handleClick}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "var(--light-mode-gray-100, #001233)",
            display: userStatus?.edit_station ? "block" : "none",
          }}
          aria-label="options"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenModal(cardData.id, cardData.name, event);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomOptionsModal
          isOpen={isModalOpen}
          anchorEl={anchorEl}
          onClose={(e) => handleCloseModal(e)}
          ID={id}
          handleEdit={handleEditFormOpen}
        />
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            sx={{ width: "80px", height: "100%", flexShrink: 0 }}
            image={cardData?.pictures ? cardData?.pictures.length > 0 ? cardData.pictures[0] : carChargingDashboard : Coupon}
            alt="Custom Card Image"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "hidden" }}>
          <CardContent style={{ paddingBottom: "0px" }}>
            <Typography
              component="div"
              style={{
                overflow: "hidden",
                color: "var(--light-mode-gray-100, #001233)",
                textOverflow: "ellipsis",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                whiteSpace: "nowrap",
              }}
            >
              {isLongText ?
                <marquee>
                  {cardData.name}
                </marquee>
                : cardData.name}
            </Typography>
          </CardContent>
          <Box
            sx={{
              position: "relative",
              paddingLeft: "16px",
              paddingBottom: "16px",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              style={{
                color: "var(--light-mode-gray-60, #979DAC)",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
                cursor: 'pointer',
                textTransform: "capitalize",
                paddingRight: "20px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "32px",
              }}
              title={`${cardData.address}, ${cardData.pincode}, ${cardData.country}`}
            >
              {`${cardData.address}, ${cardData.pincode}, ${cardData.country}`}
            </Typography>
          </Box>

        </Box>
      </Card>
      <DynamicFormModal
        isOpen={isDynamicModalOpen}
        onClose={() => setIsDynamicModalOpen(false)}
        apiCallType={dynamicModalType}
        id={selectedCharger?.id}
      />
    </>
  );
};

export default CustomCard;