import Checkbox from '@mui/material/Checkbox';
import {TextField, Grid, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const CustomMultiSelect = ({
    label,
    options,
    placeholder,
    col_size,
    required,
    onValueChange,
    value: propValue,
    errorMessage,
    isError
}) => {

    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        if (propValue?.length > 0) {
            setSelectedValue(propValue?.map(item => options.find(value => value?.id === item)))
        }
    }, [propValue])

    const changeHandler = (e, newValue) => {
        console.log("newValue", newValue)
        let change = []
        if (newValue?.length > 0) {
            change = newValue?.map(item => item.id)
        }
        setSelectedValue(newValue);
        onValueChange(change);
    }

    return (
        <Grid item xs={col_size}>
            <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px", marginBottom: "16px" }}>
                {label}
                {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
            </Typography>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                value={selectedValue}
                onChange={changeHandler}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                             <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            <img src={option.icon ?? ''} alt={option.name} width='20px' heght='15px' style={{margin: '0 10px 0 0'}}/>
                            {option.name}
                        </li>
                    );
                }}
                style={{ width: "100%", borderRadius: '12px' }}
                renderInput={(params) => (
                    <TextField {...params}  placeholder={placeholder} />
                )}
            />
        </Grid>
    );
}

export default CustomMultiSelect;