import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import api from "../../apiSetup/apiCall";
import DynamicFormModal from "./DynamicFormModal";
import { setUserId } from "../../slice/EditUserSlice";
import { useDispatch } from "react-redux";

const CustomEmail = ({
  label,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
  errorMessage,
  isError,
  search_api
}) => {
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [endPoint, setEndPoint] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(propValue!== null ? propValue:"");
  }, [propValue]);


  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onValueChange(newValue);
    setHelperText("");
  };

  const handleModalOpen = () => {
    const modalType = localStorage.getItem("dynamicModalType");
    let userType = "";
      if(modalType === "addUser") {
        userType = "editUser"
      }
      else if(modalType === "addAdmin") {
        userType = "editAdmin"
      }
    localStorage.setItem("dynamicModalType", userType);
    setIsDynamicModalOpen(true);
  };

  const onBlur = async() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (required && !value.trim()) {
      setHelperText("Please enter an email address");
    } else if (value.trim() && !emailRegex.test(value)) {
      setHelperText("Please enter a valid email address");
    }

    try {
      const modalType = localStorage.getItem("dynamicModalType")
      let userType = "";
      if(modalType === "addUser") {
        userType = "user"
      }
      else if(modalType === "addAdmin") {
        userType = "admin"
      }
      if((modalType === "addUser" || modalType === "addAdmin") && value !== "") {
        const searchUrl = search_api ? `${search_api}/${value}/` : `prometheus/search/${userType}/${value}/`;
        const response = await api.get(searchUrl);
        dispatch(setUserId(response?.data?.data?.user_id))
        if (response?.data?.data?.api !== false) {
          setEndPoint(response?.data?.data?.api)
          handleModalOpen();
        }
    }
    } catch (error) {
      console.error("Error in API calls:", error);
    }
  };

  return (
    <>
      <Grid item xs={col_size}>
        <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
          {label}
          {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
        </Typography>
        <TextField
          style={{ marginLeft: "0" }}
          variant="outlined"
          fullWidth
          margin="normal"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          helperText={ <Typography
            variant="caption"
            color="error"
            style={{ marginTop: "5px", marginLeft: "0" }}
          >
            {isError ? errorMessage : helperText}
          </Typography>}
          error={isError}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            style: {
              borderRadius: "12px",
              display: "flex",
              height: "44px",
              padding: "12px",
              alignItems: "center",
              gap: "12px",
            },
          }}
          FormHelperTextProps={{
            style: { marginLeft: "0" }
          }}
          required={required}
        />
      </Grid>
      <DynamicFormModal
        isOpen={isDynamicModalOpen}
        onClose={() => setIsDynamicModalOpen(false)}
        editTabEndpoint={endPoint}
      />
    </>
  );
};

export default CustomEmail;

