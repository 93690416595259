import { configureStore } from "@reduxjs/toolkit";
import TableSlice from "../slice/TableSlice";
import StationListSlice from "../slice/StationListSlice";
import ChargerLogsSlice from "../slice/ChargerLogsSlice";
import EditChargerSlice from "../slice/EditChargerSlice";
import EditChargerSessionSlice from "../slice/EditChargerSessionSlice";
import LoginSlice from "../slice/LoginSlice";
import AddStationSlice from "../slice/AddStationSlice";
import SubmitStationSlice from "../slice/SubmitStationSlice";
import UserSlice from "../slice/UserSlice";
import AddChargerSlice from "../slice/AddChargerSlice";
import SubmitChargerSlice from "../slice/SubmitChargerSlice";
import EditStationSlice from "../slice/EditStationSlice";
import SubmitEditStationSlice from "../slice/SubmitEditStationSlice";
import SubmitEditChargerSlice from "../slice/SubmitEditChargerSlice";
import SubmitEditChargerSessionSlice from "../slice/SubmitEditChargerSessionSlice";
import AddUserSlice from "../slice/AddUserSlice";
import SubmitUserSlice from "../slice/SubmitUserSlice";
import EditUserSlice from "../slice/EditUserSlice";
import SubmitEditUserSlice from "../slice/SubmitEditUserSlice";
import ChargerTransitionsSlice from "../slice/ChargerTransitionsSlice";
import DateFilterSlice from "../slice/DateFilterSlice";
import CountrySlice from "../slice/CountrySlice";
import UserLinkedStationSlice from "../slice/UserLinkedStationSlice";
import UpdateUserCountrySlice from "../slice/UpdateUserCountrySlice";
import BillingTableSlice from "../slice/BillingTableSlice";
import BillingDetailsSlice from "../slice/BillingDetailsSlice";
import SessionRangeSlice from "../slice/SessionRangeSlice";
import UserTableSlice from "../slice/UserTableSlice";
import UserDetailsSlice from "../slice/UserDetailsSlice";
import UserRangeSlice from "../slice/UserRangeSlice";
import AddAdminSlice from "../slice/AddAdminSlice";
import EditAdminSlice from "../slice/EditAdminSlice";
import DeleteUserAdminSlice from "../slice/DeleteUserAdminSlice";
import DynamicTableApiCallSlice from "../slice/DynamicTableApiCallSlice";
import SubmitAdminSlice from "../slice/SubmitAdminSlice";
import SubmitEditAdminSlice from "../slice/SubmitEditAdminSlice";
import AddCouponSlice from "../slice/AddCouponSlice";
import CouponListSlice from "../slice/CouponListSlice";
import EditCouponSlice from "../slice/EditCouponSlice";
import SubmitEditCouponSlice from "../slice/SubmitEditCouponSlice";
import SubmitAddCoupon from "../slice/SubmitAddCoupon";
import GetConfigurationSlice from "../slice/GetConfigurationSlice";

const Store = configureStore({
  reducer: {
    table: TableSlice,
    stationList: StationListSlice,
    chargerLogs: ChargerLogsSlice,
    login: LoginSlice,
    addStation: AddStationSlice,
    submitStation: SubmitStationSlice,
    user: UserSlice,
    addCharger: AddChargerSlice,
    submitCharger: SubmitChargerSlice,
    editStation: EditStationSlice,
    submitEditStation: SubmitEditStationSlice,
    editCharger: EditChargerSlice,
    submitEditCharger: SubmitEditChargerSlice,
    editChargerSession: EditChargerSessionSlice,
    submitEditChargerSession: SubmitEditChargerSessionSlice,
    addUser: AddUserSlice,
    submitUser: SubmitUserSlice,
    editUser: EditUserSlice,
    submitEditUser: SubmitEditUserSlice,
    chargerTransition: ChargerTransitionsSlice,
    datePicker: DateFilterSlice,
    country: CountrySlice,
    userLinkedStation: UserLinkedStationSlice,
    updateUserCountry: UpdateUserCountrySlice,
    billingTable: BillingTableSlice,
    billingDetails: BillingDetailsSlice,
    sessionRange: SessionRangeSlice,
    userTable: UserTableSlice,
    userDetails: UserDetailsSlice,
    userRange: UserRangeSlice,
    addAdmin: AddAdminSlice,
    editAdmin: EditAdminSlice,
    deleteUserAdmin: DeleteUserAdminSlice,
    dynamicTableApiCall: DynamicTableApiCallSlice,
    submitAdmin: SubmitAdminSlice,
    submitEditAdmin: SubmitEditAdminSlice,
    addCoupon: AddCouponSlice,
    couponList: CouponListSlice,
    editCoupon: EditCouponSlice,
    submitEditCoupon: SubmitEditCouponSlice,
    submitAddCoupon: SubmitAddCoupon,
    getConfiguration: GetConfigurationSlice,
  },
});

export default Store;
