import {
    Grid,
    Paper,
    Typography,
    styled,
    Button,
    Divider,
    CircularProgress,
} from "@mui/material";
import ProfileSection from "../ProfileSection";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from "react-redux";
import noStations from "../../assets/emptyData/noStations.svg";
import Fab from "@mui/material/Fab";
import CustomCard from "../CustomCard";
import AddIcon from "@mui/icons-material/Add";
import EmptyData from "../EmptyData";
import { fetchCouponList } from "../../slice/CouponListSlice";
import api from "../../apiSetup/apiCall";
import DynamicTable from "../customComponents/DynamicTable";
import CustomTabs from "../customComponents/CustomTabs";
import DynamicFormModal from "../customComponents/DynamicFormModal";
import CustomSearch from "../customComponents/CustomSearch";
import NewDatePicker from "../NewDatePicker";
import ExportExcel from "../buttonComponents/ExportExcel";
import * as XLSX from "xlsx";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Coupon = () => {

    const coupons_data = useSelector((state) => state.couponList.data.data);
    const coupon_list_loading = useSelector((state) => state.couponList.isLoading);
    const userStatus = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [coupons, setCoupons] = useState([]);
    const [couponData, setCouponData] = useState(null);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [searchedCoupon, setSearchedCoupon] = useState(null);
    const [loader, setLoader] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
    const [dynamicModalType, setDynamicModalType] = useState("");
    const { selectedDate } = useSelector(state => state.datePicker);
    const [isStationListOpen, setIsStationListOpen] = useState(true);

    const apiTabs = couponData && couponData.tabs ? couponData.tabs : [];

    const StyledFab = styled(Fab)({
        zIndex: 1,
        top: -45,
        color: "#fff",
        background: "#467CEB",
        "&:hover": {
            background: "#467CEB",
        },
    });

    const handleStationModalOpen = () => {
        localStorage.setItem("dynamicModalType", "addCoupon");
        setDynamicModalType("addCoupon");
        setIsDynamicModalOpen(true);
    };

    const handleCardClick = (id) => {
        setSelectedCoupon(id);
        setTabValue(0);
    }


    const fetchCouponData = async (startDate, endDate) => {
        try {
            setLoader(true);
            const response = await api.get(`prometheus/coupon/${selectedCoupon}/${startDate}/${endDate}/`);
            setCouponData(response.data.data);
            setLoader(false);
        }
        catch (error) {
            setCouponData(null);
            console.log(error);
            setLoader(false);
        }
    }

    useEffect(() => {
        if (coupons_data && Array.isArray(coupons_data))
            setCoupons(coupons_data.map(item => ({ id: item.id, name: item.coupon_code, country: item.is_active ? "Active" : "Not-Active", address: item.coupon_type, pincode: `Amount: ${item.discount_amount}` })))
    }, [coupons_data])

    useEffect(() => {
        dispatch(fetchCouponList())
    }, [])

    useEffect(() => {
        if (selectedCoupon && selectedDate) {
            const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
            const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
            fetchCouponData(startISOString, endISOString)
        }
    }, [selectedCoupon, selectedDate])

    useEffect(() => {
        if (searchValue?.length > 0 && coupons && Array.isArray(coupons)) {
            setSearchedCoupon(coupons.filter(item => {
                const itemLowerCase = item?.name?.toLowerCase();
                const searchValueLowerCase = searchValue.toLowerCase();
                return itemLowerCase.includes(searchValueLowerCase);
            }))
        }
        else
            setSearchedCoupon(coupons);
    }, [coupons, searchValue])

    const handleExportToExcelClick = () => {
        const ws = XLSX.utils.json_to_sheet([]);
        const wb = XLSX.utils.book_new();

        const currentTabData = couponData.tabs[tabValue];
        let buttonTabs = [];
        if (Array.isArray(currentTabData.table_content) && currentTabData.table_content.length > 0) {
            const values = Object.entries(currentTabData.table_content[0])
            values?.forEach(item => {
                if (item[1]?.type === "button" || item[1]?.type === "toggle")
                    buttonTabs.push(item[0])
            })
        }

        const headers = currentTabData.headers
            .filter(header => header.id !== "id" && header.id !== "edit" && header.id !== "currency" && !buttonTabs.includes(header.id))
            .map(header => header?.label);

        // Add headers to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

        let tableContent = [];
        if (Array.isArray(currentTabData.table_content)) {
            tableContent = currentTabData.table_content.map(item => {
                const rowData = currentTabData.headers
                    .filter(header => header.id !== "id" && header.id !== "edit" && header.id !== "currency" && !buttonTabs.includes(header.id))
                    .map(header => {
                        const value = item[header.id];
                        return typeof value === "object" ? value?.label : value;
                    });
                return rowData;
            });
        }

        // Add table content to the worksheet
        XLSX.utils.sheet_add_aoa(ws, tableContent, { origin: "A2" });

        const sheetName = currentTabData.name || 'Sheet1';
        XLSX.utils.book_append_sheet(wb, ws, sheetName);

        // Save Excel file
        let selected_coupon_code = (coupons?.filter(item => item.id === selectedCoupon))[0].name
        let fileName = `${selected_coupon_code}_${currentTabData?.name}`
        fileName = `${fileName.toLowerCase()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const renderButtons = () => {
        const currentTabData = apiTabs[tabValue];

        if (currentTabData && currentTabData.filters_and_buttons) {
            const placeholder = `Search ${currentTabData.name || "Item"}`;
            return currentTabData.filters_and_buttons.map((button, index) => {
                switch (button) {
                    case "date_range_filter":
                        return (
                            <NewDatePicker facilityId={couponData?.id} />
                        );
                    case "Export to Excel":
                        return (
                            <ExportExcel key={index} onClick={handleExportToExcelClick} />
                        );
                    default:
                        return null;
                }
            });
        }
    };

    const handleSidebarOpen = () => {
        setIsStationListOpen(false)
    }


    return (
        <Grid>
            <Grid container rowSpacing={3} columnSpacing={3} alignItems='center' style={{ backgroundColor: "white", marginBottom: "20px" }}>
                <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px', alignItems: 'center', display: "flex" }}>
                    <Typography variant="h4" fontSize='20px' fontWeight='600'>Coupon Page</Typography>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: "5px" }}>
                    <ProfileSection />
                </Grid>
            </Grid>
            {
                coupon_list_loading ?
                    <Grid
                        item
                        xs={12}
                        md={9}
                        paddingInline='20px'
                        height='80vh'
                    >
                        <Grid container alignItems="center" justifyContent="center" height="inherit">
                            <CircularProgress />
                        </Grid>
                    </Grid> :
                    <Grid container>
                        {isStationListOpen ? (
                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{
                                    borderRadius: "16px",
                                    background: "#FFF",
                                    boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                    padding: "16px",
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "12px",
                                        marginBottom: "20px"
                                    }}
                                >
                                    <CustomSearch
                                        placeholder="Search Coupon"
                                        onSearch={(value) => setSearchValue(value)}
                                        value={searchValue}
                                    />
                                    <Grid
                                        onClick={handleSidebarOpen}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            alignSelf: "stretch",
                                            background: "var(--color-styles-white, #FFF)",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <CloseIcon
                                            sx={{
                                                display: "flex",
                                                width: "24px",
                                                height: "24px",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        ></CloseIcon>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{ padding: 0 }}
                                    rowSpacing={3}
                                    columnSpacing={3}
                                >
                                    {searchedCoupon?.length > 0 ? (
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                maxHeight: "calc(100vh - 200px)",
                                                overflow: "auto",
                                            }}
                                        >
                                            <PerfectScrollbar
                                                sx={{
                                                    overflow: "auto",
                                                    maxHeight: "100%",
                                                    scrollbarWidth: "thin", // For Firefox
                                                }}
                                            >
                                                {Array.isArray(searchedCoupon) &&
                                                    searchedCoupon?.map((coupon) => (
                                                        <CustomCard
                                                            key={coupon?.id}
                                                            cardData={coupon}
                                                            apiCallType="editCoupon"
                                                            onClick={() => handleCardClick(coupon.id)}
                                                            isSelected={selectedCoupon === coupon.id}
                                                            onSelect={() => handleCardClick(coupon.id)}
                                                        />
                                                    ))}
                                            </PerfectScrollbar>
                                        </Grid>
                                    ) : (
                                        <EmptyData
                                            imagePath={noStations}
                                            message="No Coupons Found"
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    position="fixed"
                                    color="primary"
                                    display={userStatus?.data?.data?.add_station ? "block" : "none"}
                                    sx={{ bottom: "15px", left: "22%" }}
                                    onClick={handleStationModalOpen}
                                >
                                    <StyledFab
                                        color="#467CEB"
                                        background="#467CEB"
                                        aria-label="add"
                                    >
                                        <AddIcon sx={{ fontSize: "30px" }} />
                                    </StyledFab>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                md={.5}
                                sx={{
                                    borderRadius: "16px",
                                    background: "#FFF",
                                    boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                    padding: "1px",
                                    paddingLeft: "0px",
                                }}

                                style={{ paddingLeft: "15px", height: "calc(100vh - 100px)", display: "flex" }}
                            >
                                <Grid
                                    container
                                    sx={{ padding: 0 }}
                                    rowSpacing={0}
                                    columnSpacing={0}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "1px",
                                        }}
                                    >
                                        <Grid
                                            onClick={() => setIsStationListOpen(true)}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                alignSelf: "stretch",
                                                background: "var(--color-styles-white, #FFF)",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <ArrowForwardIosIcon
                                                sx={{
                                                    display: "flex",
                                                    width: "24px",
                                                    height: "24px",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }}
                                            ></ArrowForwardIosIcon>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {
                            !loader ?
                                !couponData ?
                                    <Grid
                                        item
                                        xs={12}
                                        md={isStationListOpen ? 9 : 11.5}
                                        paddingInline='20px'
                                        height='85vh'
                                    >
                                        <Grid container alignItems="center" justifyContent="center" height="inherit" fontSize="24px">
                                            Please select a coupon to see the data.
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid paddingInline="10px"
                                        item
                                        xs={12}
                                        md={isStationListOpen ? 9 : 11.5}
                                    >
                                        <Grid
                                            sx={{
                                                borderRadius: "16px",
                                                background: "#FFF",
                                                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                                paddingBlock: '16px'
                                            }}
                                            minHeight='85vh'
                                        >
                                            <Grid sx={{ paddingInline: '16px' }}>
                                                <CustomTabs
                                                    formTabs={couponData?.tabs}
                                                    tabValue={tabValue}
                                                    handleTabChange={(e, newValue) => setTabValue(newValue)}
                                                />
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "12px",
                                                    margin: "16px 20px",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {renderButtons()}
                                            </Grid>

                                            <DynamicTable
                                                setSelectedData={setTabValue}
                                                selectedData={null}
                                                tabValue={tabValue}
                                                searchedData={couponData.tabs[tabValue].table_content}
                                                tabs={couponData?.tabs}
                                            />
                                        </Grid>
                                    </Grid>
                                :
                                <Grid
                                    item
                                    xs={12}
                                    md={9}
                                    paddingInline='20px'
                                    height='80vh'
                                >
                                    <Grid container alignItems="center" justifyContent="center" height="inherit">
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>}

            <DynamicFormModal
                isOpen={isDynamicModalOpen}
                onClose={() => setIsDynamicModalOpen(false)}
                editTabEndpoint={dynamicModalType === 'editStation' ? `prometheus/station/edit/coupon/` : 'prometheus/station/add/coupon/'}
                id={selectedCoupon}
            />
        </Grid>
    );
}

export default Coupon;