import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import CustomFormTabs from "./CustomFormTabs";
import CustomMultilineInput from "./CustomMultiLineInput";
import CustomFileUpload from "./CustomFileUpload";
import CustomRadioButton from "./CustomRadioButton";
import CustomToggle from "./CustomToggle";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomEmail from "./CustomEmail";
import CustomPhoneNumber from "./CustomPhoneNumber";
import CustomNumber from "./CustomNumber";
import CustomHidden from "./CustomHidden";
import { useSelector } from "react-redux";
import CustomDateInput from "./CustomDateInput";
import CustomInputWithButton from "./CutomInputWithButton";
import CustomButton from "./CustomButton";
import CustomMultiSelect from "./CustomMultiSelect";

const DynamicForm = ({ props }) => {
  const [errors, setErrors] = useState({});
  const submitChargerData = useSelector(state => state.submitCharger.apiErrorData);
  const submitStationData = useSelector(state => state.submitStation.data);
  const submitUserData = useSelector(state => state.submitCharger.data);
  const submitAdminData = useSelector(state => state.submitCharger.data);
  const submitEditCouponData = useSelector(state => state.submitEditCoupon.data);
  const submitAddCouponData = useSelector(state => state.submitAddCoupon.data);

  const modalType = localStorage.getItem("dynamicModalType");

  const handleInputChange = (tab, inputName, inputValue) => {
    if (inputValue === "true") inputValue = true;
    if (inputValue === "false") inputValue = false;
    if (inputValue !== undefined)
      props.onFormValuesChange(tab, inputName, inputValue);
  };

  useEffect(() => {
    let selectedData;
    switch (modalType) {
      case "addCharger":
        selectedData = submitChargerData;
        break;
      case "addStation":
        selectedData = submitStationData;
        break;
      case "addUser":
        selectedData = submitUserData;
        break;
      case "addAdmin":
        selectedData = submitAdminData;
        break;
      case "editCoupon":
        selectedData = submitEditCouponData;
        break;
      case "addCoupon":
        selectedData = submitAddCouponData;
        break;
      default:
        selectedData = submitChargerData;
    }

    if (selectedData && selectedData.data && selectedData.data.tabs) {
      const tabErrors = selectedData.data.tabs.reduce((acc, tab) => {
        const tabInputErrors = tab.form_input.reduce((inputErrors, input) => {
          if (input.is_error) {
            inputErrors[input.name] = input.error_message[0];
          }
          return inputErrors;
        }, {});
        return { ...acc, ...tabInputErrors };
      }, {});
      setErrors(tabErrors);
    } else {
      setErrors({});
    }
  }, [modalType, submitChargerData, submitStationData, submitUserData, submitAdminData, submitEditCouponData, submitAddCouponData]);

  useEffect(() => {
    setErrors({});
  }, []);

  useEffect(() => {
  }, [props.formData, props.onFormValuesChange, props.currentTab]);

  const renderFormInputs = (formInputs) => {
    return (
      formInputs &&
      formInputs.map((input, index) => {
        const { type, name, ...rest } = input;
        switch (type) {
          case "action_button":
            return <CustomButton key={index} {...rest} />;
          case "input_text_with_button":
            return (
              <CustomInputWithButton
                key={index}
                button = {formInputs?.find(item => item.key === ('button_' + input.name))}
                {...rest}
              />
            );
          case "input_text":
            return (
              <CustomInput
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "input_text_multiline":
            return (
              <CustomMultilineInput
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "input_email":
            return (
              <CustomEmail
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "input_number":
            return (
              <CustomNumber
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value || "")}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "input_phone":
            return (
              <CustomPhoneNumber
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value || '')}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "dropdown":
            return (
              <CustomSelect
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value || "")}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          case "autocomplete":
            return <CustomAutoComplete key={index} {...rest} />;
          case "radio":
            return (
              <CustomRadioButton
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                {...rest}
              />
            );
          case "toggle":
            return (
              <CustomToggle
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                key={index}
                {...rest}
              />
            );
          case "hidden":
            return (
              <CustomHidden
                onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
                key={index}
                {...rest}
              />
            );
          case "browse":
            return <CustomFileUpload key={index} onValueChange={(value) => handleInputChange(props.currentTab, name, value)} {...rest} />;
          case "date":
            return <CustomDateInput
              key={index} {...rest}
              name={name}
              onValueChange={(value) => handleInputChange(props.currentTab, name, value)}
              isError={errors[name]}
              errorMessage={errors[name]}
            />          
            case "multiselect":
            return (
              <CustomMultiSelect
                key={index}
                onValueChange={(value) => handleInputChange(props.currentTab, name, value || [])}
                isError={errors[name]}
                errorMessage={errors[name]}
                {...rest}
              />
            );
          default:
            return null;
        }
      })
    );
  };

  return (
    <>
      <CustomFormTabs
        props={{
          key: Math.random(),
          formTabs: props.formData?.tabs,
          tabValue: props.currentTab,
          handleTabChange: props.handleTabChange
        }}
      />
      <Grid
        container
        spacing={{ xs: 2 }}
        columns={{ xs: 4, sm: 2, md: 12 }}
        style={{
          maxHeight: "85vh",
          overflow: "auto",
          paddingTop: "24px",
          paddingBottom: "54px",
        }}
      >
        {props.formData?.tabs &&
          props.formData?.tabs[props.currentTab]?.form_input &&
          renderFormInputs(props.formData?.tabs[props.currentTab].form_input)}
      </Grid>
    </>
  );
};

export default DynamicForm;
