import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";

export const fetchEditChargerSessionForm = createAsyncThunk(
  "editChargerSession/fetchEditChargerSessionForm",
  async (selectedChargerSessionEdit, { rejectWithValue }) => {
    try {
      const response = await api.get(`${selectedChargerSessionEdit}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching edit charger session form", error);
      throw rejectWithValue(error);
    }
  }
);

const EditChargerSessionSlice = createSlice({
  name: "editChargerSession",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditChargerSessionForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditChargerSessionForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditChargerSessionForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = true;
      });
  },
});

export default EditChargerSessionSlice.reducer;
