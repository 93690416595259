import { createSlice } from "@reduxjs/toolkit";


const dateFilterSlice = createSlice({
    name: 'datePicker',
    initialState: {
        selectedDate: {
            start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , 23, 59, 59, 999),
            startForDetails: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 23, 59, 59,999)
        },
        logsSelectedDate: {
            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , 23, 59, 59, 999),
            startForDetails: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59,999)
        }
    },
    reducers: {
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setSelectedLogsDate: (state, action) => {
            state.logsSelectedDate = action.payload;
        },
        refreshLogsSelectedDate: (state,action) =>{
            state.logsSelectedDate = {
                start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , 23, 59, 59, 999),
                startForDetails: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59,999)
            }
        }
    }
}
)

export const { setSelectedDate, setSelectedLogsDate, refreshLogsSelectedDate } = dateFilterSlice.actions;
export default dateFilterSlice.reducer;