import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastContainer = () => {
	return (
		<ToastContainer
			position="bottom-center"
			autoClose={800}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick={true}
			rtl={false}
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover
			theme="colored"
		/>
	);
};

export const compressImage = (file, quality) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event) => {
			const img = new Image();
			img.src = event.target.result;
			img.onload = () => {
				let width = img.width;
				let height = img.height;
				const maxSize = 500;

				if (width > height) {
					if (width > maxSize) {
						height = (height * maxSize) / width;
						width = maxSize;
					}
				} else {
					if (height > maxSize) {
						width = (width * maxSize) / height;
						height = maxSize;
					}
				}

				const canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext('2d');

				ctx.drawImage(img, 0, 0, width, height);

				canvas.toBlob(
					(blob) => {
						if (blob) {
							resolve(new File([blob], file.name, { type: file.type }));
						} else {
							reject(new Error("Compression failed."));
						}
					},
					file.type,
					quality
				);
			};
		};

		reader.onerror = (error) => reject(error);
	});
};
