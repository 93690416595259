import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import {
  Container,
  Grid,
  Typography,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clearLoginState } from "../slice/LoginSlice";
import { updateUserCountryData } from "../slice/UpdateUserCountrySlice";

const ProfileSection = () => {

  const [anchorElUser, setAnchorElUser] = useState(null);
  const countryData = useSelector((state) => state.country.data);
  const userData = useSelector((state) => state.user.data);
  const updateCountryData = useSelector((state) => state.updateUserCountry);

  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('selectedCountry')  || '');

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getInitials = (name) => {
    const nameArray = name.split(" ");
    return nameArray.map((word) => word[0]).join("");
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
    handleCloseUserMenu();
  };

  const handleLogoutClick = () => {
    navigate("/login");
    localStorage.clear();
    handleCloseUserMenu();
    dispatch(clearLoginState());
  };

  const handleCountryChange = (event) => {
    const newSelectedCountry = event.target.value;
    setSelectedCountry(newSelectedCountry);
  
    const selectedCountryId = countryData?.data?.find(
      (country) => country.name === newSelectedCountry
    )?.id;
  
    const selectedCountryName = countryData?.data?.find(
      (country) => country.name === newSelectedCountry
    )?.name;
  
    let selectedLanguage = "en";
  
    if (selectedCountryName === "Thailand") {
      selectedLanguage = "th";
    } else if (selectedCountryName === "Japan") {
      selectedLanguage = "ja";
    } else if (selectedCountryName === "Indonesia") {
      selectedLanguage = "id";
    }
  
    localStorage.setItem('selectedCountry', newSelectedCountry);
    localStorage.setItem('selectedLanguage', selectedLanguage);

    // Commented Out Code for Language Change Based on Country Selection.

    // dispatch(updateUserCountryData(selectedCountryId))
    //   .then(() => {
    //     window.location.href = `/?lang=${selectedLanguage}`;
    //   })

      dispatch(updateUserCountryData(selectedCountryId)).then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };  

  useEffect(() => {
    if (userData?.data?.country && !selectedCountry) {
      setSelectedCountry(userData?.data?.country);
      const storedLanguage = localStorage.getItem('selectedLanguage') || 'en';
      i18n.changeLanguage(storedLanguage);
    }
  }, [userData]);  

  const getCountryFlagUrl = (shortCode) =>
    `https://flagcdn.com/w20/${shortCode.toLowerCase()}.png`;

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "Transparent",
        boxShadow: "none",
        paddingRight: "17px",
        paddingBottom: "0px",
      }}
    >
      <Container maxWidth="xl" style={{ padding: "0", margin: "0" }}>
        <Toolbar disableGutters style={{ justifyContent: "end" }}>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {userData?.data?.name && (
                  <Avatar alt={userData.data.name} src="/static/images/avatar/2.jpg">
                    {getInitials(userData.data.name)}
                  </Avatar>
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleDashboardClick}>
                <Typography textAlign="center">{t("dashboard")}</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                <Typography textAlign="center">{t("logout")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "12px",
            }}
          >
            <Typography
              variant="h8"
              style={{ color: "#001233", fontWeight: "600", textTransform: 'capitalize' }}
            >
              {userData?.data?.name}
            </Typography>
            <Typography variant="body2" style={{ color: "#7D8597", textTransform: "capitalize" }}>
              {userData?.data?.facility_admin_type_display_text}
            </Typography>
          </Grid>
          {countryData?.data?.length > 0 &&
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              style={{ marginLeft: "12px" }}
              size="small"
            >
              <MenuItem value="" disabled>
                Select Country
              </MenuItem>
              {countryData?.data?.map((country) =>
                <MenuItem
                  key={country.id}
                  value={country.name}
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <img
                    alt={country.name}
                    src={getCountryFlagUrl(country.short_code)}
                  />{" "}
                  {country.name}
                </MenuItem>
              )}
            </Select>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ProfileSection;
