import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import api from "../../apiSetup/apiCall";

const CustomButton = ({
  label,
  col_size,
  apiEndpoint,
  disabled,
  color,
  bg_color,
}) => {
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    if (window.confirm("Are you confirm?")) {
      try {
        setLoading(true);
        const response = await api.post(apiEndpoint);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        return error.response.data;
      }
    }
  };

  return (
    <>
      <Grid
        xs={col_size}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "16.5px 14px",
        }}
      >
        <Button
          variant="contained"
          style={{
            padding: "10px",
            height: "40px",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={submitHandler}
          disabled={disabled || loading}
        >
          {label}
        </Button>
      </Grid>
    </>
  );
};

export default CustomButton;
